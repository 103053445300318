import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "./firebase";
import styled, { keyframes } from "styled-components";
import { Mail, Lock, LogIn, UserPlus, AlertCircle, Key } from 'lucide-react'; // Import Lucide Icons
import logo from './logo.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const FormWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 1rem;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const Snackbar = styled.div`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  min-width: 250px;
  background-color: #f44336;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  font-size: 17px;
  display: flex;
  align-items: center;
  animation: ${(props) => (props.show ? slideIn : slideOut)} 0.5s;
`;

const SnackbarIcon = styled.div`
  margin-right: 0.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.isRegistering ? slideIn : slideOut)} 0.5s;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 0.75rem;
`;

const InputIcon = styled.div`
  margin-right: 0.5rem;
  color: #4ECB71;
`;

const Input = styled.input`
  border: none;
  background: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #21873E;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #74937D;
  }
`;

const NeutralButton = styled(Button)`
  background-color: #f0f2f5;
  color: #74937D;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonIcon = styled.div`
  margin-right: 0.5rem;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);  
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TabSelector = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? "#21873E" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#74937D")};
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  border-radius: 24px;

  &:hover {
    background: #74937D;
    color: white;
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

function SignIn({ onSignIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        onSignIn(); // Call the parent component's sign-in handler
      } else {
        setError(true);
        setVerificationMessage("Please verify your email before signing in.");
      }
    } catch (error) {
      setError(true);
      setVerificationMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setVerificationMessage("A verification email has been sent. Please check your inbox.");
    } catch (error) {
      setError(true);
      setVerificationMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleForgotPassword = () => {
    const auth = getAuth();
    const emailAddress = email; // Assuming `email` is the state variable holding the email input value
  
    if (!emailAddress) {
      alert("Please enter your email address.");
      return;
    }
  
    sendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        alert("Password reset email sent!");
      })
      .catch((error) => {
        console.error("Error sending password reset email:", error);
        alert("Error sending password reset email. Please try again.");
      });
  };

  return (
    <Container>
      <FormWrapper>
        <Logo src={logo} alt="Logo" /> 
        <TabSelector>
          <TabButton active={!isRegistering} onClick={() => setIsRegistering(false)}>
            Sign In
          </TabButton>
          <TabButton active={isRegistering} onClick={() => setIsRegistering(true)}>
            Register
          </TabButton>
        </TabSelector>
        <Form onSubmit={isRegistering ? handleRegister : handleSignIn} isRegistering={isRegistering}>
          <InputWrapper>
            <InputIcon><Mail /></InputIcon>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </InputWrapper>
          <InputWrapper>
            <InputIcon><Lock /></InputIcon>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </InputWrapper>
          <Button type="submit" disabled={loading}>
            {loading ? <Spinner /> : (
              <>
                <ButtonIcon>{isRegistering ? <UserPlus /> : <LogIn />}</ButtonIcon>
                {isRegistering ? "Register" : "Sign In"}
              </>
            )}
          </Button>
          {!isRegistering && (
            <NeutralButton type="button" onClick={handleForgotPassword}>
              <ButtonIcon><Key /></ButtonIcon>
              Forgot Password
            </NeutralButton>
          )}
        </Form>
      </FormWrapper>
      <Snackbar show={error}>
        <SnackbarIcon><AlertCircle /></SnackbarIcon>
        {verificationMessage}
      </Snackbar>
    </Container>
  );
}

export default SignIn;