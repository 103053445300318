import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBBUbmkp3HCConoNOs3kvHWIighQaxEd-U",
  authDomain: "evcard-it.firebaseapp.com",
  projectId: "evcard-it",
  storageBucket: "evcard-it.appspot.com",
  messagingSenderId: "856491802326",
  appId: "1:856491802326:web:aa3cb24cec16fef98e1377",
  measurementId: "G-CV2J3HWZK9"
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export const auth = getAuth(app);
export { database };
