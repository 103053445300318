// src/HomePage.js

import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { database } from "./firebase";
import EvidenceCard from "./EvidenceCard";
import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: #444;
  margin-bottom: 30px;
`;

function HomePage() {
  const [evidenceList, setEvidenceList] = useState([]);

  useEffect(() => {
    const fetchEvidence = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "evidence"));
        const evidenceData = querySnapshot.docs.map((doc) => doc.data());
        setEvidenceList(evidenceData);
      } catch (error) {
        console.error("Error fetching evidence:", error);
      }
    };

    fetchEvidence();
  }, []);

  return (
    <Container>
      <Title>Debate Evidence</Title>
      <div>
        {evidenceList.map((evidence, index) => (
          <EvidenceCard key={index} evidence={evidence} />
        ))}
      </div>
    </Container>
  );
}

export default HomePage;
