// src/EvidenceCard.js

import React from "react";
import styled from "styled-components";

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Quote = styled.blockquote`
  font-style: italic;
  font-size: 1.2em;
  color: #333;
`;

const Author = styled.p`
  font-weight: bold;
  margin-top: 8px;
  color: #555;
`;

const Tags = styled.div`
  margin-top: 8px;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 0.9em;
  color: #555;
`;

const SourceLink = styled.a`
  display: block;
  margin-top: 12px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function EvidenceCard({ evidence }) {
  const { quote, author, url, tags } = evidence;

  return (
    <Card>
      <Quote>"{quote}"</Quote>
      <Author>- {author}</Author>
      {tags && tags.length > 0 && (
        <Tags>
          {tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </Tags>
      )}
      {url && (
        <SourceLink href={url} target="_blank" rel="noopener noreferrer">
          View Source
        </SourceLink>
      )}
    </Card>
  );
}

export default EvidenceCard;
